<template>
  <router-link
    tag="div"
    class="menu_element_block flex align-middle items-center"
    :to="'/' + path"
  >
    <div class="menu_element ml-4 text-c18 w-full text-left">
      {{ menu }}
    </div>
  </router-link>
</template>

<script>

export default {
  name: '',
  props: {
    menu: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      resultat: '#ffffff',
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/sass/mixin';
.menu_element_block{
  position: relative;
  cursor: pointer;
  width: 100%;
  font-weight: 400;
  letter-spacing: 0.0168em;
  color: #606060;
  &.router-link-active{
    color: black;
    .menu_element{
      color: black;
      font-weight: 600;
    }
  }
}

.menu_element_block:hover{
    .menu_element{
      color: #3521B5
    }
}
</style>
