<template>
  <div class="the_sidebar bg-white pt-6 pb-6 fixed">
    <div class="flex items-center justify-center pl-6 pr-6">
      <icon
        :data="icons.logo"
        height="60"
        width="60"
        class="mr-2"
        @click="accueil"
        original
      />
      <div class="agence h-60 flex items-center pl-2 pr-2 bg-f3 ml-3 rounded-11 w-4/5">
        <div class="w-full mt-1">
          <div class="flex items-center">
            <icon
              :data="icons.agence"
              height="40"
              width="40"
              class="mr-1"
              original
            />
            <div class="text-c18 font-c6 text-left text-dark-violet">
              Comptabilité
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="defilOption pb-20 pt-16 mt-4">
      <div class="mt-6 pl-8 pr-6">
        <div class="flex items-center">
          <icon
            :data="icons.compta"
            height="20"
            width="20"
            class="mr-3"
            original
          />
          <div class="text-left text-c18 text-60 text-lite-violet">
            Comptabilité
          </div>
        </div>


        <div class="mt-5 bord ml-10">
          <menu-element
            menu="Commandes"
            path="Commandes"
          />
          <!--          <menu-element-->
          <!--              menu="Dépenses"-->
          <!--              path="depenses"-->
          <!--              class="mt-4"-->
          <!--          />-->
          <menu-element
            menu="Factures internes"
            path="facture"
            class="mt-4"
          />
          <menu-element
            menu="Contrôle factures"
            path="controle"
            class="mt-4"
          />
          <menu-element
            menu="Caisses"
            path="caisses"
            class="mt-4"
          />
          <menu-element
            menu="Banques"
            path="banques"
            class="mt-4"
          />
          <menu-element
            v-if="verifRole () === true"
            menu="Validation"
            path="validation"
            class="mt-4"
          />
          <!--          <menu-element-->
          <!--              menu="Recouvrements"-->
          <!--              path="Recouvrements"-->
          <!--              class="mt-4"-->
          <!--          />-->
          <!--          <menu-element-->
          <!--              menu="Compte fournisseurs"-->
          <!--              path="Compte fournisseurs"-->
          <!--              class="mt-4"-->
          <!--          />-->
          <!--          <menu-element-->
          <!--              menu="Personnel"-->
          <!--              path="Personnel"-->
          <!--              class="mt-4"-->
          <!--          />-->
          <!--          <menu-element-->
          <!--              menu="Bilan simplifié"-->
          <!--              path="Bilan simplifié"-->
          <!--              class="mt-4"-->
          <!--          />-->
        </div>
      </div>

      <!--      <div class="mt-6 pl-10 pr-6">-->
      <!--        <div class="flex items-center">-->
      <!--          <icon-->
      <!--              :data="icons.stock"-->
      <!--              height="17"-->
      <!--              width="17"-->
      <!--              class="mr-3"-->
      <!--              original-->
      <!--          />-->
      <!--          <div class="text-left text-c18 text-60 text-lite-violet">-->
      <!--            Stock-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        <div class="mt-5 bord ml-10">-->
      <!--          <menu-element-->
      <!--              menu="Stock principal"-->
      <!--              path="stock"-->
      <!--          />-->
      <!--          <menu-element-->
      <!--              menu="Réception | Livraison"-->
      <!--              path="reception"-->
      <!--              class="mt-4"-->
      <!--          />-->
      <!--          <menu-element-->
      <!--              menu="Montage en instance"-->
      <!--              path="montage"-->
      <!--              class="mt-4"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import menuElement from './side-menu-element'
import agence from '../../assets/icons/agenceGray.svg'
import logo from '../../assets/icons/logo.svg'
import user from '../../assets/icons/userS.svg'
import compta from '../../assets/icons/compta.svg'
import stock from '../../assets/icons/stock.svg'


export default {
  name: 'TheSidebar',
  components: {
    menuElement,
  },
  data () {
    return {
      vente: ['devis', 'commandes'],
      icons: {
        agence,
        logo,
        user,
        compta,
        stock
      },
      user: null,
      role: null
    }
  },

  created () {
    this.user = this.parseJwt(this.$store.getters.token)
    this.role = this.user.type
  },

  methods: {
    parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''));
      return JSON.parse(jsonPayload)
    },

    verifRole () {
      if (this.role === 'SUPRA_ADMIN') {
        return true
      } else if (this.role === 'ACCOUNTING') {
         if (this.user.id === 'ce31ae92-d6d4-4fc1-865a-bb5074bbc92f') {
           return true
         } else {

           return false
         }
      } else if (this.role === 'DIRECTION') {
        if (this.user.id === '8044f728-21bb-4184-85f2-959f82207b34') {
          return true
        } else {

          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/styles/sass/variables';
.the_sidebar{
  height: 100vh;
  border-right: 1px solid #EAEAEF;
}
.defilOption{
  height: 100%;
  max-height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.defilOption::-webkit-scrollbar {
  display: none;
}
.svg-icon {
  fill: transparent;
}
.bord {
  border-left: 1px solid #DDDDDD;
}
</style>
